import React from "react";
import Fade from "react-reveal/Fade";
import VisibilitySensor from "react-visibility-sensor";
class BenefitsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show1: false, show2: false };
    }
    renderLanguage(selectedLang) {
        let PARAGRAPH_1,
            PARAGRAPH_2,
            PARAGRAPH_3,
            PARAGRAPH_4,
            DOCUMENT_HEADER,
            contentClass;
        if (selectedLang === "eng") {
            contentClass = "englishcontent";
            PARAGRAPH_1 = "70% of the rewards will be shared with our voters.";
            PARAGRAPH_2 =
                "25% of the rewards will be used to cover the engineering and operation cost";
            PARAGRAPH_3 =
                "5% of the rewards will be used to organizing online campaigns and offline meetups for IoTeX";
            PARAGRAPH_4 =
                "Reward will be distributed daily by Hermes (https://hermes.to/)";
            DOCUMENT_HEADER = "Reward Distribution";
        }
        if (selectedLang === "kor") {
            contentClass = "koreancontent";

            PARAGRAPH_1 = "보상금의 70%는 유권자들과 나눠서 사용해야 합니다.";
            PARAGRAPH_2 =
                "포상금의 25%는 공학과 운영비를 충당하는데 사용할 것입니다.";
            PARAGRAPH_3 =
                "포상금의 5%는 IoTeX 온라인 캠페인과 오프라인 미팅을 조직하는 데 사용할 것입니다.";
            PARAGRAPH_4 =
                "*Reward will be distributed daily by Hermes (https://hermes.to/)";
            DOCUMENT_HEADER = "보상금 분배";
        }
        return (
            <div className={`${contentClass} document-body`}>
                <div className="document-header">{DOCUMENT_HEADER}</div>
                <div className="document-content">
                    <p>{PARAGRAPH_1}</p>
                    <p>{PARAGRAPH_2}</p>
                    <p>{PARAGRAPH_3}</p>
                    <p>
                        <sub>{PARAGRAPH_4}</sub>
                    </p>
                </div>
            </div>
        );
    }
    render() {
        let { sectionSelectorName, selectedLang } = this.props;
        return (
            <div className={`section ${sectionSelectorName} sec-reward`}>
                <div className="clearfix">
                    {/* <div className="section-content-text right"> */}
                    <VisibilitySensor
                        onChange={(visible) => {
                            this.setState({ show2: visible });
                        }}
                        offset={{ top: 40, bottom: 20 }}
                    >
                        <Fade
                            bottom
                            opposite
                            when={this.state.show2}
                            distance={"15px"}
                        >
                            <div className="reward-group centerblock">
                                <div className="floatParagraph spinoff right">
                                    {this.renderLanguage(selectedLang)}
                                </div>
                            </div>
                        </Fade>
                    </VisibilitySensor>
                </div>
                {/* <div className="section-content-image left">
                        <img
                            src={mainImg}
                            className="sectionimg"
                            alt="Benefit Section Image"
                        />
                    </div>
                </div> */}
            </div>
        );
    }
}

export default BenefitsPage;
