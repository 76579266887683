import React from "react";

import Fade from "react-reveal/Fade";
import mainImg from "../images/new/CommunityPlan.png";
import VisibilitySensor from "react-visibility-sensor";
class CommunityPlanPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show1: false, show2: false };
    }
    renderLanguage(selectedLang) {
        let PARAGRAPH_1,
            PARAGRAPH_2,
            PARAGRAPH_3,
            PARAGRAPH_4,
            PARAGRAPH_5,
            DOCUMENT_HEADER,
            contentClass;
        if (selectedLang === "eng") {
            contentClass = "englishcontent";
            PARAGRAPH_1 =
                "As the early supporters in the Korean community, EnlightiVision will continue contributing to the community growth in Korea, including";
            PARAGRAPH_2 =
                "Organize and expand the online community in multiple channels";
            PARAGRAPH_3 = "Organize local meet-ups";
            PARAGRAPH_4 = "Build local connections with universities";
            PARAGRAPH_5 =
                "Connect and work with potential Dapp developers to strengthen the IoTeX network";
            DOCUMENT_HEADER = "Community Plan";
        }
        if (selectedLang === "kor") {
            contentClass = "koreancontent";
            PARAGRAPH_1 =
                "한국 커뮤니티 최초의 지지자인 EnlightiVision은 앞으로도 다양한 채널를 통해 조직과 온라인 확대를 하여 한국 커뮤니티의 성장에 기여할 것입니다.";
            PARAGRAPH_2 = "다양한 채널에서 온라인 커뮤니티 구성 및 확장";
            PARAGRAPH_3 = "Meet-up계획";
            PARAGRAPH_4 = "대학과 지방성관계를 맺습니다.";
            PARAGRAPH_5 =
                "잠재적 Dapp 개발자와 연결 및 작업하여IoTeX 네트워크를 강화 합니다.";
            DOCUMENT_HEADER = "커뮤니티 계획";
        }
        return (
            <div className={`${contentClass} document-body`}>
                <div className="document-header">{DOCUMENT_HEADER}</div>
                <div className="document-content">
                    <div className="communityplan-intro-document">
                        {PARAGRAPH_1}
                        <br />
                    </div>
                    <p>{PARAGRAPH_2}</p>
                    <p>{PARAGRAPH_3}</p>
                    <p>{PARAGRAPH_4}</p>
                    <p>{PARAGRAPH_5}</p>
                </div>
            </div>
        );
    }
    render() {
        let { sectionSelectorName, selectedLang } = this.props;
        return (
            <div className={`section ${sectionSelectorName} sec-communityplan`}>
                <div className="clearfix">
                    {/* <div className="section-content-text right"> */}
                        <VisibilitySensor
                            onChange={visible => {
                                this.setState({ show2: visible });
                            }}
                            offset={{ top: 40, bottom: 20 }}
                        >
                            <Fade
                                bottom
                                opposite
                                when={this.state.show2}
                                distance={"15px"}
                            >
                                <div className="communityplan-group centerblock">
                                    <div className="floatParagraph spinoff right">
                                        {this.renderLanguage(selectedLang)}
                                    </div>
                                </div>
                            </Fade>
                        </VisibilitySensor>
                    </div>
                    {/* <div className="section-content-image left">
                        <img
                            src={mainImg}
                            className="sectionimg"
                            alt="Community Plan Section Image"
                        />
                    </div> */}
               {/*  </div> */}
            </div>
        );
    }
}

export default CommunityPlanPage;
