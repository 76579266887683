import "./style/style.scss";
import React from "react";
import ReactDOM from "react-dom";
import { AppRouter } from "./route/AppRouter";
import "react-app-polyfill/ie9";
import { ThemeProvider, createTheme, Arwes } from 'arwes';

ReactDOM.render(
    <ThemeProvider theme={createTheme()}>{AppRouter}</ThemeProvider>,
    document.getElementById("root")
);
