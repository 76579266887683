import React from "react";
import Fade from "react-reveal/Fade";
import iotexlogoblack from "../images/all-black.svg";
import iotexlogowhite from "../images/white.svg";
import VisibilitySensor from "react-visibility-sensor";
import Footer from "../components/Footer";
class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show1: false, show2: false };
    }

    render() {
        let { sectionSelectorName } = this.props;
        return (
            <div className={`section ${sectionSelectorName} fp-auto-height sec-contact`}>
                <div className="clearfix last-section">
                    <VisibilitySensor
                        onChange={visible => {
                            this.setState({ show2: visible });
                        }}
                    >
                        <Fade when={this.state.show2}>
                            <div className="contact-group centerblock">
                                <div className="englishcontent">
                                    <div className="contact-section">
                                        <div className="socialMedia-connect">
                                            <div className="socialMedia-heading">
                                                Vote For Us!
                                            </div>
                                            <div className="socialMedia-content">
                                                <ul>
                                                    <li>
                                                        <a
                                                            href="https://member.iotex.io/delegate/5c8a3d1740fc846b1d88cc1f"
                                                            target="_blank"
                                                            className="contact-iotex-voting-icon"
                                                        >
                                                            <img
                                                                src={
                                                                    iotexlogoblack
                                                                }
                                                                alt="iotex logo"
                                                                width="20px"
                                                                height="37px"
                                                                className="contact-iotex-icon"
                                                            />
                                                            <img
                                                                src={
                                                                    iotexlogowhite
                                                                }
                                                                alt="iotex logo"
                                                                width="20px"
                                                                height="37px"
                                                                className="contact-iotex-icon-hover"
                                                            />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="socialMedia-connect">
                                            <div className="socialMedia-heading">
                                                Let's Connect!
                                            </div>
                                            <div className="socialMedia-content">
                                                <ul>
                                                    <li>
                                                        <a
                                                            href="https://twitter.com/enlightivision"
                                                            target="_blank"
                                                            className="contact-twitter-icon"
                                                        >
                                                            <i
                                                                className="fa fa-twitter"
                                                                aria-hidden="true"
                                                            />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://www.facebook.com/enlightivision"
                                                            target="_blank"
                                                            className="contact-facebook-icon"
                                                        >
                                                            <i
                                                                className="fa fa-facebook"
                                                                aria-hidden="true"
                                                            />
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a
                                                            href="https://t.me/enlightivision"
                                                            target="_blank"
                                                            className="contact-telegram-icon"
                                                        >
                                                            <i
                                                                className="fa fa-telegram"
                                                                aria-hidden="true"
                                                            />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="https://medium.com/@enlightivision"
                                                            target="_blank"
                                                            className="contact-medium-icon"
                                                        >
                                                            <i
                                                                className="fa fa-medium"
                                                                aria-hidden="true"
                                                            />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/*<div className="contact-form">
                                            <form
                                                action="https://formspree.io/otwick@gmail.com"
                                                method="POST"
                                            >
                                                <input
                                                    type="text"
                                                    name="name"
                                                    size="20"
                                                    placeholder="Name"
                                                />
                                                <input
                                                    type="email"
                                                    name="_replyto"
                                                    size="20"
                                                    placeholder="Email"
                                                />
                                                <textarea
                                                    rows="10"
                                                    cols="35"
                                                    placeholder="Your message"
                                                />
                                                <input
                                                    type="submit"
                                                    value="Send"
                                                    size="20"
                                                />
                                            </form>
                                        </div>
                                     */}
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </Fade>
                    </VisibilitySensor>
                </div>
            </div>
        );
    }
}

export default ContactPage;
