import React from "react";
import ReactFullpage from "@fullpage/react-fullpage";

import Header from "../components/Header";
//import Countdown from "./CountDown";
import IntroductionPage from "../components/IntroductionPage";
import CommunityPlanPage from "../components/CommunityPlanPage";
import TechSetupPage from "../components/TechSetupPage";
import AboutPage from "../components/AboutPage";
import BenefitsPage from "../components/BenefitsPage";
import ContactPage from "../components/ContactPage";
import ExperiencePage from "../components/ExperiencePage";
import "fullpage.js/vendors/scrolloverflow";
//http://www.aurasens.com/#
const SEL = "custom-section";
const SECTION_SEL = `.${SEL}`;

// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const pluginWrapper = () => {
    require("fullpage.js/vendors/scrolloverflow");
    /**
     * require('fullpage.js/vendors/scrolloverflow'); // Optional. When using scrollOverflow:true
     */
};
const originalColors = ["#282c34", "#ff5f45", "#0798ec"];

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionsColor: [...originalColors],
            selectedLang: "eng",
            fullpages: [
                {
                    text: "section 1",
                },
                {
                    text: "section 2",
                },
            ],
        };
        this.handleLanguangeContext = this.handleLanguangeContext.bind(this);
    }

    onLeave(origin, destination, direction) {
        //console.log("onLeave", { origin, destination, direction });
        // arguments are mapped in order of fullpage.js callback arguments do something
        // with the event
    }
    handleLanguangeContext(selectedLang) {
        //console.log('set lang',selectedLang)
        this.setState({ selectedLang: selectedLang });
    }
    render() {
        const { fullpages, selectedLang } = this.state;
        //console.log("curr selected lang",selectedLang)
        if (!fullpages.length) {
            return null;
        }

        return (
            <div className="Main">
                <Header
                    toggleLang={this.handleLanguangeContext}
                    selectedLang={selectedLang}
                />
                <ReactFullpage
                    /* debug Debug logging */
                    licenseKey={"8424AD39-CDA44329-89F26BFF-77A416AD"}
                    navigation
                    navigationTooltips={[
                        "We are One",
                        "Who we are",
                        "Our Powerhouse",
                        "To Grow Together",
                        "Our Team",
                        "Share Our Glory",
                        "Be Part Of Us",
                    ]}
                    scrollOverflow
                    navigationPosition={"right"}
                    scrollingSpeed={1700}
                    showActiveTooltip
                    responsiveWidth={932}
                    responsiveHeight={800}
                    responsiveSlides
                    anchors={[
                        "WeareOne",
                        "Whoweare",
                        "OurPowerhouse",
                        "ToGrowTogether",
                        "OurTeam",
                        "Benefits",
                        "Contact",
                    ]}
                    sectionSelector={SECTION_SEL}
                    onLeave={this.onLeave.bind(this)}
                    pluginWrapper={pluginWrapper}
                    render={({ state, fullpageApi }) => {
                        return (
                            <div>
                                {/*
                                <CountDownPage
                                    sectionSelectorName={SEL}
                                    selectedLang={selectedLang}
                                />
                                */}
                                <IntroductionPage
                                    sectionSelectorName={SEL}
                                    selectedLang={selectedLang}
                                />
                                <AboutPage
                                    sectionSelectorName={SEL}
                                    selectedLang={selectedLang}
                                />
                                <TechSetupPage
                                    sectionSelectorName={SEL}
                                    selectedLang={selectedLang}
                                />

                                <CommunityPlanPage
                                    sectionSelectorName={SEL}
                                    selectedLang={selectedLang}
                                />
                                <ExperiencePage
                                    sectionSelectorName={SEL}
                                    selectedLang={selectedLang}
                                />
                                <BenefitsPage
                                    sectionSelectorName={SEL}
                                    selectedLang={selectedLang}
                                />
                                <ContactPage
                                    sectionSelectorName={SEL}
                                    selectedLang={selectedLang}
                                />
                            </div>
                        );
                    }}
                />
            </div>
        );
    }
}
export default Main;
