const tokenNameValidator = (rule, value, callback) => {
    let regex = /^[a-zA-Z0-9-]+(\s+[a-zA-Z0-9-]+)*$/;

    if (regex.test(value) && value.length >= 3 && value.length <= 25) {
        return callback();
    }
    callback(
        "Only 3-25 Alphanumerical characters, including space and hyphen are accepted. space character is not allowed at the begining or the end of the string"
    );
};

const tokenSymbolValidator = (rule, value, callback) => {
    let regex = /^([a-zA-Z0-9]){3,4}$/;
    if (regex.test(value)) {
        return callback();
    }
    callback("Only 3-4 alphanumerical characters are accepted. ");
};

const decimalsValidator = (rule, value, callback) => {
    let regex = /^([0-9]{0,18})$/;
    if (regex.test(value) && parseInt(value) >= 0 && parseInt(value) <= 18) {
        return callback();
    }
    callback("Only 0-18 numerals characters are accepted");
};

const tokenSupplyValidator = (rule, value, callback) => {
    let regex = /^([0-9]{1,1000000000000000})$/;
    if (regex.test(value) && parseInt(value) >= 1 && parseInt(value) <= 1000000000000000) {
        console.log("tokenSupplyValidator passed");
        return callback();
    }
    callback("Minimum value is 1, and maximum 1000000000000000");
};

const mintFormMd = [
    {
        fieldName: "tokenName",
        fieldLabel: "Token Name",
        validation: [
            {
                required: true,
            },
            {
                validator: tokenNameValidator,
            },
        ],
        desc:
            "The name of the token. 3-25 symbols. Alphanumerical characters, space, and hyphen are accepted.",
    },
    {
        fieldName: "tokenSymbol",
        fieldLabel: "tokenSymbol",
        validation: [
            {
                required: true,
            },
            {
                validator: tokenSymbolValidator,
            },
        ],
        desc:
            "3-4 characters (example ETH, BTC, BAT, etc.). No spaces. Only alphanumerical characters.",
    },
    {
        fieldName: "decimals",
        fieldLabel: "Decimals",
        validation: [
            {
                required: true,
            },
            {
                validator: decimalsValidator,
            },
        ],
        desc:
            "Defines the number of decimals for the token. 0-18 numerals are accepted. 18 is common practice.",
    },
    {
        fieldName: "tokenSupply",
        fieldLabel: "Token Supply",
        validation: [
            {
                required: true,
            },
            {
                validator: tokenSupplyValidator,
            },
        ],
        desc:
            "Initial amount of tokens to be generated. Minimum value is 1, and maximum 1000000000000000.",
    },
];

export default mintFormMd;
