import React from "react";
import Fade from "react-reveal/Fade";
import VisibilitySensor from "react-visibility-sensor";
class TechSetupPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show1: false, show2: false };
    }

    render() {
        let { sectionSelectorName } = this.props;
        return (
            <div
                className={`section ${sectionSelectorName} sec-tech fp-auto-height-responsive`}
            >
                <div className="clearfix">
                    {/* <div className="section-content-text right"> */}
                    <VisibilitySensor
                        onChange={visible => {
                            this.setState({ show2: visible });
                        }}
                    >
                        <Fade
                            bottom
                            opposite
                            when={this.state.show2}
                            distance={"20px"}
                        >
                            <div className="techsetup-group centerblock">
                                <div className="floatParagraph spinoff right">
                                    <div className="document-body">
                                        <div className="document-header">
                                            Tech Setup
                                        </div>
                                        <div className="document-content">
                                            <p>
                                                Hardware Server: 1 Primary
                                                server and 1 backup server (AWS)
                                            </p>
                                            <p>Memory: 32 GB of RAM</p>
                                            <p>Local Storage: 4 TB SSD</p>
                                            <p>CPU: 64-bit</p>
                                            <p>
                                                Processor: 8 Cores (2.4 GHz
                                                each)
                                            </p>
                                            <p> Network: At least 100 MB/sec</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </VisibilitySensor>
                    {/*  </div> */}
                    {/*  <div className="section-content-image left">
                        <img
                            src={mainImg}
                            className="sectionimg"
                            alt="Tech Setup Section Image"
                        />
                    </div> */}
                </div>
            </div>
        );
    }
}

export default TechSetupPage;
