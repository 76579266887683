
import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import createHistory from 'history/createBrowserHistory';
import Main from "../pages/Main"
import Mint from "../pages/Mint"

export const history = createHistory()
const unauthenticatedPages = ['/', '/mint'];
const authenticatedPages = ['/rtPrice'];
export const onAuthChange = (isAuthenticated) => {
    const pathname = history.location.pathname;
    const isUnauthenticatedPage = unauthenticatedPages.includes(pathname);
    let isAuthenticatedPage = false;
    for (let i = 0; i < authenticatedPages.length; i++) {
        isAuthenticatedPage = isAuthenticatedPage || pathname.indexOf(authenticatedPages[i]) != -1;
    }
    if (isUnauthenticatedPage && isAuthenticated) {
        history.replace('/rtPrice');
    } else if (isAuthenticatedPage && !isAuthenticated) {
        history.replace('/');
    }
}



export const AppRouter = (
    <Router history={history}>
        <Switch>
            <Route exact path="/" component={Main} />
            <Route exact path="/main" component={Main} />
            <Route exact path="/mint" component={Mint} />
        </Switch>
    </Router>
);

