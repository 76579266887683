import React from "react";

import "antd/dist/antd.css";
import { Arwes, Button, Words } from "arwes";
import { Form, Input, Select } from "antd";

import formMetaData from "./metadata/mintForm";

const layout = {
    labelCol: {
        span: 4,
        offset: 4
    },
    wrapperCol: {
        span: 12,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 12,
    },
};

class MintFormControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.renderAntdFormInputField = this.renderAntdFormInputField.bind(
            this
        );
    }
    formRef = React.createRef();

    onFinish = (values) => {
        // print form result
        console.log(values);
    };

    onReset = () => {
        this.formRef.current.resetFields();
    };

    renderAntdFormInputField({
        fieldName,
        fieldLabel,
        required,
        desc,
        validation,
        index,
    }) {
        return (
            <Form.Item
                extra={desc}
                key={index}
                name={fieldLabel}
                label={fieldLabel}
                rules={validation}
                hasFeedback
                className="mint-form-item"
            >
                <Input />
            </Form.Item>
        );
    }

    render() {
        return (
            <div className="center-div">
                <Form
                    layout="horizontal"
                    {...layout}
                    ref={this.formRef}
                    name="control-ref"
                    onFinish={this.onFinish}
                    className="mint-form-primary"
                >
                    {formMetaData.map((item, index) =>
                        this.renderAntdFormInputField({ ...item, index })
                    )}

                    <Form.Item {...tailLayout} className="cyan-cp-text form-control-btn-group">
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button htmlType="button" onClick={this.onReset}>
                            Reset
                        </Button>
                    </Form.Item>
                   
                </Form>
            </div>
        );
    }
}

export default MintFormControl;
