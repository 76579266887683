import React from "react";
import Fade from "react-reveal/Fade";
import brandLogo from "../images/enlightivisionLogo.svg";
import { Link } from "react-router-dom";
class Header extends React.Component {
    handleLanguageOptionRender(selectedLang, toggleLang) {
        //console.log('selectedLang',selectedLang)
        //console.log('toggleLang',toggleLang)

        switch (selectedLang) {
            case "eng":
            default:
                return (
                    <div
                        className="menu-item"
                        onClick={() => toggleLang("kor")}
                    >
                        한국어
                    </div>
                );
            case "kor":
                return (
                    <div
                        className="menu-item"
                        onClick={() => toggleLang("eng")}
                    >
                        English
                    </div>
                );
        }
    }
    render() {
        let { selectedLang, toggleLang } = this.props;
        return (
            <div className="header">
                <Fade bottom distance={"10px"}>
                    <div className="logo-container">
                        <a href="http://enlightivision.com/">
                            <img src={brandLogo} />
                        </a>
                    </div>

                    {/* <div className="header-menu-container">
                        <div className="menu-list">
                            <Link to="/mint" className="menu-list">
                                Mint Coin with XRC20 (IOTX based)
                            </Link>
                        </div>
                    </div> */}

                    <div className="header-menu-container">
                        <div className="menu-list">
                            {this.handleLanguageOptionRender(
                                selectedLang,
                                toggleLang
                            )}
                        </div>
                    </div>
                </Fade>
            </div>
        );
    }
}

export default Header;
