import React from "react";

import Fade from "react-reveal/Fade";
import VisibilitySensor from "react-visibility-sensor";
class AboutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show1: false, show2: false };
    }
    renderLanguage(selectedLang) {
        let PARAGRAPH_1, PARAGRAPH_2, contentClass;
        if (selectedLang === "eng") {
            contentClass = 'englishcontent'
            PARAGRAPH_1 =
                "EnlightiVision aims to build a collaborative community in Korea for IoTeX Network. As one of the early supporters of the project, we truly believe and support IoTeX’s vision to build a fast, flexible and privacy-centric blockchain for Internet of Things. Our vision strongly aligns with IoTeX’s combining blockchain, trusted hardware and edge computing to realize the full potential of IoT.";
            PARAGRAPH_2 =
                "Starting from early 2018, we have contributed a lot on introducing IoTeX to the Korean community by providing promotional materials and translations. We will continue to devote ourselves in helping IoTeX to continue expand a solid community in Asia as a delegate in 2019. Our team consists of marketing experts to spread the word in multiple channels and staff engineers provide 24/7 support to the node running. As a transparent and rewarding delegate, we want to enlighten our community and let the future of IoTeX shine through our vision!";
        }
        if (selectedLang === "kor") {
            contentClass = 'koreancontent'
            PARAGRAPH_1 =
                "EnlightiVision은 IoTeX Network를 위해 한국에 협력 커뮤니티를 만들 계획입니다.이 프로젝트의 초기 지지자 중의 한 사람으로서 우리는 사물인터넷을 위한 빠르고 유연하며 프라이버시 중심의 블록체인을 건설하려는 IoTeX의 비전을 진심으로 믿고 지지합니다. 우리의 비전은 IoTeX의 블록체인, 믿을 수 있는 하드웨어와 가장자리 계산을 결합하여 사물인터넷의 모든 잠재력을 실현합니다.";
            PARAGRAPH_2 =
                "2018년 초부터 우리는 홍보자료와 번역를 통해 한국 커뮤니티에 IoTeX를 소개하기 위해 많은 노력을 해왔습니다.우리는 2019년 대표로서 IoTeX가 아시아 지역의 탄탄한 커뮤니티를 지속적으로 확장하도록 계속 헌신할 것입니다.우리 팀은 마케팅 전문가로 구성되어 여러 채널에서 이 단어를 퍼뜨리고 있으며, 직원 엔지니어들은 실행 중인 노드를 위해 24/7지지를 제공할것입니다.투명하고 보람 있는 대표로서 우리는커뮤니티를 계몽하고 IoTeX의 미래를 우리의 비전을 통해 빛나게 하고 싶습니다.";
        }
        return (
            <div className={`${contentClass} document-body`}>
                <div className="document-header">
                    Yesterday, Today and Tomorrow
                </div>
                <div className="document-content">
                    <p>{PARAGRAPH_1} </p>
                    <p>{PARAGRAPH_2} </p>
                </div>
            </div>
        );
    }

    render() {
        let { sectionSelectorName, selectedLang } = this.props;
        return (
            <div className={`section ${sectionSelectorName} sec-about`}>
                <div className="clearfix">
                    {/* <div className="section-content-text right"> */}
                    <VisibilitySensor
                        onChange={visible => {
                            this.setState({ show2: visible });
                        }}
                        offset={{ top: 40, bottom: 20 }}
                    >
                        <Fade
                            bottom
                            opposite
                            when={this.state.show2}
                            distance={"15px"}
                        >
                            <div className="about-group centerblock">
                                <div className="floatParagraph spinoff right">
                                {this.renderLanguage(selectedLang)}
                                </div>
                                
                                {/*  <div className="responsive-section-content-image">
                                        <img
                                            src={mainImg}
                                            className="sectionimg"
                                            alt="About Section Image"
                                        />
                                    </div>*/}
                            </div>
                        </Fade>
                    </VisibilitySensor>
                    {/* </div> */}
                    {/*
                    <div className="section-content-image left">
                        <img
                            src={mainImg}
                            className="sectionimg"
                            alt="About Section Image"
                        />
                    </div>
                    */}
                </div>
            </div>
        );
    }
}

export default AboutPage;
