import React from "react";
class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <pre>2022 EnlightiVision All Rights Reserved</pre>
            </div>
        );
    }
}

export default Footer;
