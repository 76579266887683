import React from "react";
import Fade from "react-reveal/Fade";
import VisibilitySensor from "react-visibility-sensor";
import iotexlogoblack from "../images/all-black.svg";
import iotexlogowhite from "../images/white.svg";
class IntroductionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show1: false, show2: false };
    }
    renderLanguage(selectedLang) {
        if (selectedLang === "eng") {
            return (
                <div className="englishcontent">
                    <div className="websiteSlogan">
                        Enlighten our IoTeX community
                        <br />
                        Let the future of IoTeX shine through our vision
                    </div>
                </div>
            );
        }
        if (selectedLang === "kor") {
            return (
                <div className="koreancontent">
                    <div className="websiteSlogan">
                        Enlighten our IoTeX community
                        <br />
                        Let the future of IoTeX shine through our vision
                    </div>
                </div>
            );
        }
    }
    renderAnimateButton(selectedLang) {
        let voterHandbookLocale, contentClass;
        if (selectedLang === "eng") {
            contentClass = "englishcontent";
            voterHandbookLocale = "Voter Handbook";
        }
        if (selectedLang === "kor") {
            contentClass = "koreancontent";
            voterHandbookLocale = "투표자 안내서";
        }
        return (
            <div className="resources-container">
                <div className="socialMedia-connect">
                    <div className="socialMedia-heading">Vote For Us!</div>
                    <div className="socialMedia-content">
                        <ul>
                            <li>
                                <a
                                    href="https://member.iotex.io/delegate/5c8a3d1740fc846b1d88cc1f"
                                    target="_blank"
                                    className="contact-iotex-voting-icon dark"
                                >
                                    <img
                                        src={iotexlogoblack}
                                        alt="iotex logo"
                                        width="20px"
                                        height="37px"
                                        className="contact-iotex-icon"
                                    />
                                    <img
                                        src={iotexlogowhite}
                                        alt="iotex logo"
                                        width="20px"
                                        height="37px"
                                        className="contact-iotex-icon-hover"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="socialMedia-connect">
                    <div className="socialMedia-heading">
                        {voterHandbookLocale}
                    </div>
                    <div className="socialMedia-content">
                        <div
                            className="fa-icon-btn"
                            onClick={() =>
                                window.open(
                                    "voter-handbook-engver.pdf",
                                    "_blank"
                                )
                            }
                        >
                            <i
                                className="fa fa-book voterhandbookIcon"
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        let { sectionSelectorName, selectedLang } = this.props;

        return (
            <div className={`section ${sectionSelectorName} sec-intro`}>
                <div className="clearfix">
                    <VisibilitySensor
                        onChange={(visible) => {
                            this.setState({ show1: visible });
                        }}
                        offset={{ top: 20, bottom: 20 }}
                    >
                        <Fade
                            bottom
                            opposite
                            when={this.state.show1}
                            distance={"15px"}
                        >
                            <div className="intro-group centerblock">
                                {this.renderLanguage(selectedLang)}
                            </div>
                            <div className="intro-group centerblock">
                            {this.renderAnimateButton(selectedLang)}
                            </div>
                          
                     
                           
                        </Fade>
                    </VisibilitySensor>

                    {/* <div className="section-content-image full">
                        <img
                            src={mainImg}
                            className="sectionimg"
                            alt="Introduction Section Image"
                        />
                    </div> */}
                </div>
            </div>
        );
    }
}

export default IntroductionPage;
