import React from "react";
import { Arwes, Button, Words } from "arwes";
import { Row, Col } from "antd";

import MintFormControl from "../components/mint/MintFormControl";

import mintBg from "../images/mint-background.png";
import mintBg1 from "../images/mint-purple-background.png";
import glowPtn from "../images/glow.png";
import "antd/dist/antd.css";

class Mint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <Arwes animate background={mintBg1}>
                    <Row>
                        <Col span={16} offset={4}>
                            <div style={{ padding: 20, textAlign:"center" }}>
                                <Words animate>Mintable XRC-20 Token</Words>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={20} offset={2}>
                            <MintFormControl/>
                        </Col>
                    </Row>
                </Arwes>
            </React.Fragment>
        );
    }
}

export default Mint;
