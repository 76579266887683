import React from "react";

import Fade from "react-reveal/Fade";
import VisibilitySensor from "react-visibility-sensor";
class ExperiencePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show1: false, show2: false };
    }
    renderLanguage(selectedLang) {
        let PARAGRAPH_1,
            PARAGRAPH_2,
            PARAGRAPH_3,
            PARAGRAPH_4,
            DOCUMENT_HEADER,
            contentClass;
        if (selectedLang === "eng") {
            PARAGRAPH_1 = "Sophia";
            PARAGRAPH_2 =
                "Bachelor degree in Economics. Over 4 years of experience in marketing, branding and PR. Brand Ambassador and Community Manager at IoTeX, Blockchain Enthusiast";
            PARAGRAPH_3 = "Jason";
            PARAGRAPH_4 =
                "Master Degree in Computer Science. Senior Software Engineer focusing on data visualization on business intelligence. Machine learning, algorithmic trading and blockchain technology enthusiast. IoTeX early supporter";
            contentClass = "englishcontent";
            DOCUMENT_HEADER = "The Team";
        }
        if (selectedLang === "kor") {
            PARAGRAPH_1 = "소피아";
            PARAGRAPH_2 =
                "경제학 학사. 마케팅, 브랜딩, 홍보 분야에서 4년 이상의 경험을 장악. 블록체인 열병식 IoTeX 브랜드 대사 겸 커뮤니티 매니저";
            PARAGRAPH_3 = "제이슨";
            PARAGRAPH_4 =
                "컴퓨터 공학 석사 학위. 비즈니스 인텔리전스에 대한 데이터 시각화에 주력하는 수석 소프트웨어 엔지니어. 기계학습, 알고리즘 거래, 블록체인 기술 애호가. IoTeX초기 지지자.";
            contentClass = "koreancontent";
            DOCUMENT_HEADER = "팀맨버 소개";
        }
        return (
            <div className={`${contentClass} document-body`}>
                <div className="document-header">{DOCUMENT_HEADER}</div>
                <div className="document-content">
                    <h3>{PARAGRAPH_1}</h3>
                    <div>{PARAGRAPH_2}</div>
                    <h3>{PARAGRAPH_3}</h3>
                    <div>{PARAGRAPH_4}</div>
                </div>
            </div>
        );
    }
    render() {
        let { sectionSelectorName, selectedLang } = this.props;
        return (
            <div className={`section ${sectionSelectorName} sec-team`}>
                <div className="clearfix">
                    {/* <div className="section-content-text right"> */}
                    <VisibilitySensor
                        onChange={visible => {
                            this.setState({ show2: visible });
                        }}
                        offset={{ top: 40, bottom: 20 }}
                    >
                        <Fade
                            bottom
                            opposite
                            when={this.state.show2}
                            distance={"20px"}
                        >
                            <div className="team-group centerblock">
                                <div className="floatParagraph spinoff right">
                                    {this.renderLanguage(selectedLang)}
                                </div>
                            </div>
                        </Fade>
                    </VisibilitySensor>
                    {/*    </div> */}
                    {/* <div className="section-content-image left">
                        <img
                            src={compositionImg}
                            className="sectionimg"
                            alt="Experience Page Section Image"
                        />
                    </div> */}
                </div>
            </div>
        );
    }
}

export default ExperiencePage;
